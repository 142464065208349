html {
    font-size: 62.5%;
    height: 100%;

    &.admin-tools-visible {
        @media (max-width: @screen-sm-max) {
            margin-top: 50px;
        }
    }
}

body {
    height: 100%;
}

.container {
    width: 100% !important;
}

.main-content {
    padding: 30px 0;

    @media (min-width: @screen-md-min) {
        position: fixed;
        top: @navigation-height;
        left: @sidebar-width;
        right: 0;
        bottom: 0;
        overflow: auto;
        padding: 30px 15px;

        html.admin-tools-visible & {
            top: (50 + @navigation-height);
        }
    }

    @media (min-width: @screen-lg-min) {
        padding: 45px 30px;
    }
}

hr {
    border-color: @border-color;
}

.page-header {
    margin: 15px 0 20px 0;
    border: 0;
    padding: 0;
}

.well {
    box-shadow: none;
    background: #fff;
    border-color: @border-color;
}
