.product-card {
    flex: 1 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    text-align: center;
    max-width: 450px;
    position: relative;
    background: #fff;
    border: 1px solid @border-color;
    padding: 10px 10px 20px 10px;

    a:not(.btn) {
        display: block;
        color: @text-color;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    .detail-wrap {
        flex: 1 auto;
        display: block;

        .image-wrap {
            position: relative;
            margin-bottom: 25px;
            padding: 15px;

            &:before {
                content: "";
                position: relative;
                padding-top: 100%;
                display: block;
            }

            .image {
                position: absolute;
                left: 15px;
                right: 15px;
                top: 15px;
                bottom: 15px;
                background-position: 50%;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        .name,
        .description {
            font-size: 1.4rem;
            margin: 0;
        }

        .name {
            font-weight: 600;
        }
    }

    .price-wrap {
        flex: 0 auto;
        display: block;
        padding-top: 5px;

        .price-line {
            .amount {
                font-weight: 800;
                color: @brand-primary;
                font-size: @font-size-h4;
                text-transform: uppercase;

                &.variation-range {
                    font-size: @font-size-h5;
                }
            }

            .unit {
                white-space: nowrap;
            }
        }
    }
}
