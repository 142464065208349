.main-sidebar {
    background: #fff;
    padding: 0;

    @media (min-width: @screen-md-min) {
        position: fixed;
        left: 0;
        top: @navigation-height;
        bottom: 0;
        width: @sidebar-width;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        z-index: (@zindex-navbar - 1);
        padding: 15px 15px 0px 15px;
        overflow-x: hidden;
        overflow-y: auto;
        border-right: @sidebar-border;

        html.admin-tools-visible & {
            top: (50 + @navigation-height);
        }
    }

    .search {
        margin: 10px 0;
        padding: 0 15px;

        @media (min-width: @screen-md-min) {
            padding: 0px 15px 15px 15px;
            margin: 0;
        }

        form {
            position: relative;
        }

        input {
            border: 1px solid @border-color;
            border-radius: 20px;
            height: 40px;
            padding: 0 45px 0 15px;
            width: 100%;

            &:focus {
                border-color: @brand-primary;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 5px;
            height: 40px;
            background: none;
            border: 0;
            outline: 0 !important;
            line-height: 40px;
            text-align: center;
            width: 40px;
        }
    }

    .category-title {
        padding: 15px;
        display: block;
        position: relative;
        font-weight: bold;
        margin: 0px;
        text-transform: uppercase;
        color: @text-muted;
        text-decoration: none !important;

        @media (min-width: @screen-md-min) {
            font-size: @font-size-large;
            pointer-events: none;
        }

        i {
            position: absolute;
            right: 13px;
            top: 13px;
            line-height: 20px;
            transform-origin: 50%;

            @media (min-width: @screen-md-min) {
                display: none;
            }
        }

        &.collapsed i {
            transform: rotate(180deg);
            right: 15px;
            top: 15px;
        }
    }

    .category-wrap {
        @media (min-width: @screen-md-min) {
            margin-bottom: 15px;
            // override collapse on larger screens
            display: block !important;
            height: auto !important;
            overflow: visible !important;
        }
    }

    ul.category-menu {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;

            a {
                padding: 5px 15px;
                display: block;
                transition: all .15s;
                color: @text-color;

                &:hover, &:focus {
                    text-decoration: none;
                    color: @link-color;
                }
            }

            &.level-1 > a {
                padding: 10px 15px;
                font-size: @font-size-large;

                &:hover, &:focus {
                    background: rgba(0,0,0,.02);
                }
            }

            &.level-2 {
                border-left: 2px solid rgba(0,0,0,.1);
            }

            &.level-3 a {
                font-size: @font-size-small;
            }

            &.current-page > a {
                font-weight: 600;
                color: @brand-primary;
            }

            ul {
                display: none;
                padding-left: 20px;
                margin: 5px 0 10px 0;

                ul {
                    padding-left: 10px;
                }
            }

            &.current-ancestor > ul {
                display: block;
            }
        }
    }

    .planex-logo {
        .row();
        border-top: 1px solid @border-color-light;

        @media (max-width: @screen-sm-max) {
            display: none;
        }

        a {
            display: block;
            text-align: center;
        }

        img {
            height: 60px;
            padding: 15px;
        }
    }
}
