.nav-tabs {
    border: 0;

    > li {
        float: none;
        margin-bottom: 3px;
        display: inline-block;

        @media (min-width: @screen-sm-min) {
            margin-bottom: 0;
        }

        > a {
            border: solid @border-color;
            border-width: 0 0 4px 0;
            border-radius: 0;
            margin-right: 5px;
            transition: color .15s, border-color .15s;
            color: @text-color;
            outline: 0;

            &:hover, &:focus {
                color: @brand-primary;
                border-color: darken(@border-color, 5%);
                background: none;
            }
        }

        &.active > a {
            font-weight: bold;
            border: solid transparent;
            border-width: 0 0 4px 0;
            border-bottom-color: @brand-primary;
            color: @text-color;
            background: transparent;

            &:hover, &:focus {
                color: @text-color;
                border: solid transparent;
                border-width: 0 0 4px 0;
                border-bottom-color: @brand-primary;
                background: transparent;
            }
        }

        &.disabled > a {
            color: @text-muted;
        }
    }

    .open > a {
        border-color: transparent;
        background: none;

        &:hover, &:focus {
            border-color: transparent;
            background: none;
        }
    }
}

.tab-content {
    padding: 25px 0;
}
