/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-carousel .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
}

.owl-carousel .owl-nav [class*='owl-']:hover {
    background: @brand-primary;
    color: #FFF;
    text-decoration: none;
}

.owl-carousel .owl-nav .disabled {
    opacity: 0.5;
    cursor: default;
}

.owl-carousel .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
}

.owl-carousel .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
}
.owl-carousel .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
    background: @brand-primary;
}
