.customer-dashboard {
    .section-navigation {
        background: #fff;
        margin-bottom: 30px;
        border-radius: 2px;
    }

    .sidebar-list {
        padding: 15px 0;

        a {
            display: block;
            padding: 10px 5px;
            border-bottom: 1px solid @border-color;
            border-width: 1px 0;
            color: @text-color;

            &:last-child {
                border-bottom: none;
            }

            i {
                width: 1.5em;
                text-align: center;
                color: @brand-primary;
            }
        }
    }

    .user-account,
    .user-account-address {
        background: #fff;
        padding: 15px;
        border-radius: 2px;
    }
}
