.navbar-admin-tools {
    .navbar-brand {
        padding-top: 5px;

        small {
            font-size: 60%;
        }

        .maintenance-on {
            color: green;
            font-weight: bold;
        }

        .maintenance-off {
            color: red;
            font-weight: bold;
        }
    }
}
