.category-title {
    margin: 30px 0;
}

.category-description {
    font-size: @font-size-large;
    margin-bottom: 30px;
}

.product-list {
    .row();
    display: flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;

    &.centered {
        justify-content: center;
    }

    .single-product {
        flex: 0 0 100%;
        margin-bottom: 30px;
        display: flex;
        padding: 0 15px;

        @media (min-width: 480px) {
            flex: 0 0 250px;
        }
    }
}
