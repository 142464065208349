.form-group.required {
    .control-label::after {
        content: "*";
        color: @brand-danger;
        font-size: 80%;
        margin-left: 3px;
    }
}

.thumbnail {
    background: #fff;
}

.basket-lines {
    .product-details {
        .quantity {
            .input-group {
                width: 20px;
            }
        }
    }
}

.datepicker {
    .disabled {
        background-origin: padding-box !important;
        background-color: #ff7676 !important;
        color: white !important;
        border-radius: 0px !important;
    }
}

.shipping-cost-warning {
    margin-top: 4px;
    font-size: 14px;
}

#product-price-section {
    .btn-add-to-cart {
        margin-bottom: 10px;
    }
}

.reservation-calendar {
    .available-for-rent,
    .not-available-for-rent {
        border-radius: 5px;
        padding-left: 3px;
        padding-right: 3px;
        color: white;
    }

    .available-for-rent {
        background-color: #2cbe4e;
    }

    .not-available-for-rent {
        background-color: #cb2431;
    }
}

#calendar-events-error {
    display: none;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: red;
}
