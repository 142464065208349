// Cover modal for adding products to basket
.cover-wrap {
    background: fade(@brand-primary, 90%);
    color: #fff;
    position: fixed;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -999;
    transition: all .3s;
    display: table;

    &.in {
        z-index: 30000;
        opacity: 1;
        visibility: visible;
    }

    .text-wrap {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        position: relative;
        padding: 30px;

        .load {
            display: none;
            position: absolute;
            top: 50%;
            left: 0%;
            width: 100%;
            margin-top: -2em;

            i {
                font-size: 2.5em;
            }
        }
        .ready {
            opacity: 0;
            transform: scale(0.5);
            transition: all .2s;

            i {
                font-size: 5em;
                margin-bottom: 20px;
            }
        }
        .error-msg {
            display: none;

            i {
                font-size: 5em;
                margin-bottom: 20px;
            }
        }

        p {
            font-size: 1.75em;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    &.loading {
        .load {
            display: block;
        }

        .ready {
            transform: scale(1);
        }
    }

    &.loaded {
        .ready {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.error {
        background: fade(@brand-danger, 80%);

        .error-msg {
            display: block;
        }

        .ready {
            display: none;
        }
    }
}
