.main-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    height: @navigation-height;
    background: @navigation-bg;
    z-index: @zindex-navbar;
    position: relative;

    @media (min-width: @screen-md-min) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        html.admin-tools-visible & {
            top: 50px;
        }
    }

    .nav-logo {
        float: left;
        flex: 0 0 auto;

        @media (min-width: @screen-md-min) {
            width: @sidebar-width;
        }

        a {
            display: block;
        }

        img {
            height: @navigation-height;
            max-width: 200px;
            padding: 15px;

            @media (min-width: @screen-md-min) {
                padding: 15px 30px;
            }
        }
    }

    .nav-items {
        @media (max-width: @screen-sm-max) {
            position: absolute;
            top: @navigation-height;
            right: 0;
            background: @mobile-nav-bg;
            display: none;
            width: 300px;
            padding: 15px;
            color: #fff;

            body.mobile-menu-open & {
                display: block;
            }
        }

        @media (min-width: @screen-md-min) {
            float: right;
            flex: 1 0 auto;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            padding: 0px 15px;
        }

        ul.nav-pages {
            flex: 0 auto;
            padding: 0;
            margin: 0;
            list-style: none;

            > li {
                display: block;
                position: relative;

                @media (min-width: @screen-md-min) {
                    display: inline-block;
                }

                a {
                    padding: 10px 15px;
                    display: block;
                    color: #fff;
                    border-radius: 2px;

                    @media (min-width: @screen-md-min) {
                        color: @navigation-link-color;
                    }
                }

                ul {
                    @media (min-width: @screen-md-min) {
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        background: @navigation-accent;
                    }
                }


                &:hover, &:focus, &:active {
                    @media (min-width: @screen-md-min) {
                        > a {
                            background: @navigation-accent;
                            text-decoration: none;
                            color: @navigation-link-hover-color;
                        }

                        > ul {
                            display: block;
                        }
                    }
                }

                &.current {
                    > a {
                        font-weight: bold;

                        @media (min-width: @screen-md-min) {
                            color: @navigation-link-current-color;
                        }
                    }

                    @media (min-width: @screen-md-min) {
                        &:hover, &:focus, &:active {
                            > a {
                                background: none;
                            }
                        }
                    }
                }
            }
        }

        .nav-actions {
            @media (min-width: @screen-md-min) {
                flex: 1 auto;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: flex-end;
            }

            .dropdown-menu {
                @media (min-width: @screen-md-min) {
                    left: auto;
                    right: 0;
                }
            }

            i.fa-angle-down {
                margin-left: 3px;
                font-size: 1.1em;
            }

            a {
                color: #fff;
                border-radius: 2px;

                @media (min-width: @screen-md-min) {
                    color: @navigation-link-color;

                    &:hover, &:focus {
                        background: @navigation-accent;
                        color: @navigation-link-hover-color;
                        text-decoration: none;
                    }
                }
            }

            .dropdown-menu {
                a {
                    color: @text-color !important;
                    background: transparent !important;

                    &:hover, &:focus {
                        background: @gray-lighter !important;
                    }
                }
            }
        }

        .nav-language {
            .dropdown-toggle {
                padding: 10px 15px;
                display: block;

                i.fa-globe {
                    margin-right: 3px;
                    font-size: 1.2em;
                    vertical-align: baseline;
                }
            }
        }

        .nav-basket {
            a {
                padding: 10px 15px;
                display: block;

                i.fa-shopping-bag {
                    margin-right: 3px;
                    font-size: 1.2em;
                    vertical-align: baseline;
                }
            }
        }

        .nav-user {
            .dropdown-toggle,
            > a {
                display: block;
                padding: 10px 15px;

                i.fa-sign-in,
                i.fa-user-o {
                    margin-right: 3px;
                    font-size: 1.2em;
                    vertical-align: baseline;
                }
            }
        }
    }
}

.mobile-nav-toggler {
    display: block;
    position: relative;
    height: 80px;
    width: 80px;
    border: 0;
    padding: 0;
    background: transparent;
    outline: 0;

    @media (min-width: @screen-md-min) {
        display: none;
    }

    body.mobile-menu-open & {
        background: @mobile-nav-bg;

        .hamburger {
            background: transparent;

            &:before,
            &:after {
                top: 0;
                background: @navigation-link-hover-color;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    .hamburger,
    .hamburger:before,
    .hamburger:after {
        background: @navigation-link-color;
        width: 26px;
        height: 3px;
        position: absolute;
        display: block;
        content: "";
        cursor: pointer;
        left: 50%;
        top: 40px;
        margin-top: -2px;
        margin-left: -13px;
        transition: all .2s;
    }

    .hamburger:before {
        top: -7px;
        margin-top: 0;
    }

    .hamburger:after {
        top: 7px;
        margin-top: 0;
    }
}

.mobile-menu-title {
    opacity: 0.6;
    text-transform: uppercase;
    margin: 20px 0 10px 0;
    display: block;
    font-weight: 300;
    padding: 0px 15px;
}
