// Lightbox styles for the lightbox image plugin
#imagelightbox {
    position: fixed;
    z-index: 10000;
    -ms-touch-action: none;
    touch-action: none;
}
.imagelightbox-arrow {
    width: 3.75em;
    height: 7.5em;
    background-color: @gray;
    color: #fff;
    vertical-align: middle;
    display: none;
    position: fixed;
    z-index: 10001;
    top: 50%;
    margin-top: -3.75em;
    border: 0;
    @media (max-width: @screen-xs-max) {
        display: none !important;
    }

    &-left {
        left: 2.5em;
    }
    &-right {
        right: 2.5em;
    }
}

#imagelightbox-overlay,
#imagelightbox-close,
.imagelightbox-arrow,
#imagelightbox-caption {
    -webkit-animation: fade-imagelightbox-in .25s linear;
    animation: fade-imagelightbox-in .25s linear;
}
@-webkit-keyframes fade-imagelightbox-in {
    from    { opacity: 0; }
    to      { opacity: 1; }
}
@keyframes fade-imagelightbox-in {
    from    { opacity: 0; }
    to      { opacity: 1; }
}

#imagelightbox-overlay {
    background-color: fade(@body-bg, 95%);
    position: fixed;
    z-index: 9998;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

#imagelightbox-close {
    width: 2.5em;
    height: 2.5em;
    text-align: center;
    background-color: @gray;
    border: 0;
    border-radius: 50%;
    position: fixed;
    z-index: 10002;
    top: 2.5em;
    right: 2.5em;
    transition: color 0.3s ease 0s;
    color: #fff;
}

#imagelightbox-loading {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    i {
        color: @gray-light;
        font-size: 52px;
        padding: 20px;
    }
}

#imagelightbox-caption {
    text-align: center;
    color: @text-color;
    background: fade(@body-bg, 80%);
    position: fixed;
    z-index: 10001;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1.5rem 0;
    transition: all 0.1s;
}
