@import "../base/style.less";
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

// Multishop variables and custom styles
@brand-primary: #E40613;
@brand-secondary: #174195;

@navigation-bg:     #fff;
@navigation-accent: @brand-primary;

@gray-base: #111;
@text-muted: @gray-light;

@text-color: #001737;

@navigation-link-color:        @text-color;
@navigation-link-hover-color:  #fff;
@navigation-link-current-color: @brand-primary;

@mobile-nav-bg: @text-color;

@link-color:       @brand-secondary;
@link-hover-color: @brand-primary;

@sidebar-border: 1px solid @border-color;

@font-family-base: "Cabin", sans-serif;

.main-header {
    border-bottom: 1px solid @border-color;

    .nav-logo img {
        max-width: none;
    }
}

.cover-wrap {
    background: fade(@brand-secondary, 90%);
}
