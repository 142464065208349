.main-footer {
    margin-top: 50px;
    padding: 30px 0;
    border-top: 1px solid @border-color;

    .bottom-row {
        color: @text-muted;

        @media (max-width: @screen-xs-max) {
            text-align: center;
        }

        a {
            font-weight: 600;
            color: @text-muted;
        }
    }

    .copyright {
        @media (max-width: @screen-sm-max) {
            margin-top: 5px;
        }
    }

    .shuup-powered {
        color: lighten(@text-muted, 15%);

        a {
            color: lighten(@text-muted, 15%);
        }

        @media (max-width: @screen-sm-max) {
            margin-top: 5px;
        }

        @media (min-width: @screen-sm-min) {
            text-align: right;
        }
    }

    .footer-logo {
        text-align: center;

        @media (max-width: @screen-xs-max) {
            padding: 15px 0;
        }

        a {
            display: block;
        }

        img {
            opacity: 0.4;
            height: 30px;
            width: auto;
        }
    }
}
